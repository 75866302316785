import './personal.styles.scss'
import React from 'react'

const PersonalInfo = () => {
	return (
		<>
			<div className="personalInfo">
				<div className="namIcon">AH</div>
				<div className="info">
					<div className="infoItem">
						<label>Full Name</label>
						<input type="text" value="Ahmed Ibrahim" />
					</div>
					<div className="infoItem">
						<label>User Name</label>
						<input type="text" value="Ahmed Ibrahim" />
					</div>
					<div className="infoItem">
						<label>Email</label>
						<input type="text" value="Welcome Ahmed_ibrahim@Mivors.com" />
					</div>
				</div>
			</div>
			<div className="CompanyInfo">
				<div className="namIcon">A</div>
				<div className="info">
					<div className="infoItem">
						<label>Company</label>
						<input type="text" value="Company Name" />
					</div>
					<div className="infoItem">
						<label>Website</label>
						<input type="text" value="www.company.com" />
					</div>
				</div>
			</div>
		</>
	)
}
export default PersonalInfo
