import './billig-history.styles.scss'
import React from 'react'
import { AndroidFilled } from '@ant-design/icons'

const BillingHistory = () => {
	return (
		<div className="billingHistory">
			<div className="billing-history-header">
				<ul>
					<li>Application</li>
					<li>Payment Status</li>
					<li>Issue Date</li>
					<li>Amount</li>
				</ul>
			</div>
			<div className="billing-history-data">
				<div className="billing-item">
					<div>
						<h3>Opration Excellence</h3>
						<p>ID : In_1lnA989WaUb8MewOFXzB3</p>
					</div>
					<p className="status">Paid</p>
					<p className="date">9/29/20227:04:37</p>
					<p className="amount">232 USD</p>
					<p className="print">
						<AndroidFilled />
						Print
					</p>
				</div>
				<div className="billing-item">
					<div>
						<h3>Opration Excellence</h3>
						<p>ID : In_1lnA989WaUb8MewOFXzB3</p>
					</div>
					<p className="status">Paid</p>
					<p className="date">9/29/20227:04:37</p>
					<p className="amount">232 USD</p>
					<p className="print">
						<AndroidFilled />
						Print
					</p>
				</div>
				<div className="billing-item">
					<div>
						<h3>Opration Excellence</h3>
						<p>ID : In_1lnA989WaUb8MewOFXzB3</p>
					</div>
					<p className="status">Paid</p>
					<p className="date">9/29/20227:04:37</p>
					<p className="amount">232 USD</p>
					<p className="print">
						<AndroidFilled />
						Print
					</p>
				</div>
				<div className="billing-item">
					<div>
						<h3>Opration Excellence</h3>
						<p>ID : In_1lnA989WaUb8MewOFXzB3</p>
					</div>
					<p className="status">Paid</p>
					<p className="date">9/29/20227:04:37</p>
					<p className="amount">232 USD</p>
					<p className="print">
						<AndroidFilled />
						Print
					</p>
				</div>
			</div>
		</div>
	)
}

export default BillingHistory
