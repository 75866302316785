import React, { useState } from 'react'
import '../css/profile.scss'
import { Navbar } from '../components/navbar'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { graphql } from 'gatsby'
import BillingHistory from '../components/billingHistory/BillingHistory'
import Subscription from '../components/subscription/Subscription'
import PersonalInfo from '../components/personalInfo/PersonalInfo'

const Profile = ({ data, path }) => {
	const [current, setCurrent] = useState('1')
	const categories = [
		{
			id: '1',
			title: 'Personal Info',
			component: <PersonalInfo />,
		},
		{
			id: '2',
			title: 'Subscription',
			component: <Subscription />,
		},
		{
			id: '3',
			title: 'Billing History',
			component: <BillingHistory />,
		},
		{
			id: '4',
			title: 'Billing Information',
			component: null,
		},
	]
	return (
		<section className="profile-page">
			<Navbar path={path} />
			<div className="page-content">
				<div className="profile-header">
					<h3>Profile</h3>
					<h4>Welcome Ahmed_ibrahim@Mivors.com</h4>
					<h5>Total paid amount : 0.00 USD</h5>
				</div>
				<div className="profile-action">
					<div className="categories">
						<ul>
							{categories.map(item => {
								return (
									<li
										style={{
											backgroundColor:
												current === item.id
													? '#0097c217'
													: null,
											color:
												current === item.id
													? '#0097C2'
													: null,
										}}
										onClick={() => setCurrent(item.id)}
										key={item.id}
									>
										{item.title}
									</li>
								)
							})}
						</ul>
					</div>
					<div className="category">
						{categories.find(item => item.id === current).component}
					</div>
				</div>
			</div>
			<div className="section-parent section-container footer-section">
				<MDXRenderer>{data.Footer.nodes[0].body}</MDXRenderer>
			</div>
		</section>
	)
}

export const pageQuery = graphql`
	query {
		Footer: allMdx(filter: { frontmatter: { title: { eq: "Footer" } } }) {
			nodes {
				body
			}
		}
	}
`
export default Profile
