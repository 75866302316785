import './subscription.styles.scss'
import React from 'react'

const Subscription = () => {
	return (
		<div className="subscription">
			<div className="sub-item">
				<h3>Actionable Insight</h3>
				<button>Buy Now</button>
			</div>
			<div className="sub-item">
				<h3>Actionable Insight</h3>
				<button>Buy Now</button>
			</div>
			<div className="sub-item">
				<h3>Actionable Insight</h3>
				<button>Buy Now</button>
			</div>
			<div className="sub-item">
				<h3>Actionable Insight</h3>
				<button>Buy Now</button>
			</div>
			<div className="sub-item">
				<h3>Actionable Insight</h3>
				<button>Buy Now</button>
			</div>
		</div>
	)
}

export default Subscription
